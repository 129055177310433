import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_BASE_URL } from '../constants';

type Props = {
    show: boolean,
    onHide: () => void,
}

function ContributeModal({ show, onHide }: Props) {

    const [count, setCount] = useState<string | undefined>(undefined);

    useEffect(() => {
        fetch(API_BASE_URL + '/count')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.text(); // or res.json() if sending JSON
            })
            .then((data) => setCount(data))
            .catch((err) => console.error('Error:', err));
    }, []);
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Contribute a 404 Page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3' controlId='formUrl'>
                        <Form.Label>Know a creative 404 page? Add it here.</Form.Label>
                        <Form.Control type='email' placeholder='Enter url' />
                    </Form.Group>
                </Form>
                {count && <div>{count} pages and counting</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                <Button variant='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ContributeModal;
