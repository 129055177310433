import { ImArrowUp, ImArrowDown } from 'react-icons/im';
import { Page404 } from '../../../shared/types';
import { OBSCURE_STRING } from '../constants';

type Props = {
    page404: Page404
}

function Card({ page404 }: Props) {
    
    return (
        <div className='Card'>
            <button
                className='link-button' //
                onClick={() => window.open(page404.url + '/' + OBSCURE_STRING, '_blank', 'noopener,noreferrer')}
            >
                {page404.url}
            </button>
            <button className='arrow'>
                <ImArrowUp />
            </button>
            {page404.score}
            <button className='arrow'>
                <ImArrowDown />
            </button>
        </div>
    );
}

export default Card;
